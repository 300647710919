import React from 'react';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import dashboard from '../assets/images/dashboard.jpg';
import building from '../assets/images/building.jpeg';
import room from '../assets/images/room.jpeg';
import guest from '../assets/images/guest.jpeg';
import vendor from '../assets/images/vendor.jpg';
import creditamount from '../assets/images/creditamount.png';
import debitamount from '../assets/images/debitamount.png';
import vendorbill from '../assets/images/vendorbill.jpg';
import rentreceipt from '../assets/images/rentreceipt.jpg';
import balancesheet from '../assets/images/balancesheet.png';
import user from '../assets/images/user.jpg';

const useStyles = makeStyles({
    iconMenu: {
        position: 'fixed',
        width: '100%',
        display: 'flex',
        gap: 20,
        padding: '10px 0',
        backgroundColor: '#fff',
        zIndex: -500,
        overflowX: 'auto', 
        whiteSpace: 'nowrap', 
        scrollBehavior: 'smooth', 
        '&::-webkit-scrollbar': {
          display: 'none', 
        },
      },
      iconItem: {
        display: 'inline-flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        color: 'white',
        width: '100%',
        fontSize: 14,
        marginRight: 10,
        // backgroundColor: '#eef7f7',
      },
      iconImage: {
        width: 50,
        height: 50,
        borderRadius: '50%',
        backgroundColor: 'black', 
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      },
      content: {
        paddingTop: 140, 
      },
      '@media (min-width: 1028px)': {
        iconMenu: {
          overflowX: 'hidden', 
        },
        iconItem: {
            width: '100%',
        },
        iconMenu: {
            justifyContent: 'flex-start', 
        },
      },
});

function FixedMenuLayout() {
  const classes = useStyles();

  const icons = [
    { name: 'DashBoard', imgSrc: dashboard, link: '/dashboard' },
    { name: 'Building', imgSrc: building, link: '/building_list' },
    { name: 'Rooms', imgSrc: room, link: '/room_list' },
    { name: 'Guest', imgSrc: guest, link: '/pg_form_list' },
    { name: 'Vendors', imgSrc: vendor, link: '/vendor_master_list' },
    { name: 'Credit', imgSrc: creditamount, link: '/credit_list' },
    { name: 'Debit', imgSrc: debitamount, link: '/debit_list' },
    { name: 'Vendor Bill', imgSrc: vendorbill, link: '/vendor_list' },
    { name: 'Rent Receipt', imgSrc: rentreceipt, link: '/rental_list' },
    { name: 'Balance Report', imgSrc: balancesheet, link: '/balance_sheet' },
    { name: 'User Management', imgSrc: user, link: '/user_management_list' },
  ];

  return (
    <div>
      <div className={classes.iconMenu}>
        {icons.map((icon, index) => (
          <Link to={icon.link} key={index} className={classes.iconItem}>
            <div
              className={classes.iconImage}
              style={{ backgroundImage: `url(${icon.imgSrc})` }}
            ></div>
            <p style={{fontFamily: 'sans-serif', fontSize: '12px'}}>{icon.name}</p>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default FixedMenuLayout;

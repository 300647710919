import { admin } from './admin';
import { accounts } from './accounts';
import { other } from './other';
import { dashboard } from './DashBoard';
//-----------------------|| MENU ITEMS ||-----------------------//

const menuItems = {
    items: [dashboard, admin, accounts, other]
};

export default menuItems;

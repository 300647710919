import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';

//-----------------------|| Loader ||-----------------------//

const Loader = () => {
    return (
        <Box
            sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                zIndex: 1301,
                width: '100%',
                '& > * + *': {
                    mt: 2 // Equivalent to theme.spacing(2)
                }
            }}
        >
            <LinearProgress color="primary" />
        </Box>
    );
};

export default Loader;

import React, { lazy } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Loadable from '../ui-component/Loadable';
import MainLayout from '../layout';
import AuthGuard from '../utils/route-guard/AuthGuard'

const LandingPage = Loadable(lazy(() => import('../views/pages/LandingPage')));
const PgFormData = Loadable(lazy(() => import('../components/PgFormData')));
const PgFormList = Loadable(lazy(() => import('../components/PgFormList')));
const PgFormView = Loadable(lazy(() => import('../components/PgFormView')));
const Logout = Loadable(lazy(() => import('../views/pages/authentication/Logout')));
const BuildingList = Loadable(lazy(() => import('../components/BuildingList')));
const BuildingData = Loadable(lazy(() => import('../components/BuildingData')));
const BuildingView = Loadable(lazy(() => import('../components/BuildingView')));
const UserManagementList = Loadable(lazy(() => import('../components/UserManagementList')));
const UserManagementData = Loadable(lazy(() => import('../components/UserManagementData')));
const UserManagementView = Loadable(lazy(() => import('../components/UserManagementView')));
const UserResetPassword = Loadable(lazy(() => import('../components/UserResetPassword')));
const CreditData = Loadable(lazy(() => import('../components/CreditData')));
const CreditList = Loadable(lazy(() => import('../components/CreditList')));
const CreditView = Loadable(lazy(() => import('../components/CreditView')));
const DebitList = Loadable(lazy(() => import('../components/DebitList')));
const DebitData = Loadable(lazy(() => import('../components/DebitData')));
const DebitView = Loadable(lazy(() => import('../components/DebitView')));
const GenerateRoom = Loadable(lazy(() => import('../components/GenerateRoom')));
const RoomList = Loadable(lazy(() => import('../components/RoomList')));
const VendorList = Loadable(lazy(() => import('../components/VendorList')));
const VendorData = Loadable(lazy(() => import('../components/VendorData')));
const VendorView = Loadable(lazy(() => import('../components/VendorView')));
const RentalList = Loadable(lazy(() => import('../components/RentalList')));
const RentalData = Loadable(lazy(() => import('../components/RentalData')));
const RentalView = Loadable(lazy(() => import('../components/RentalView')));
const VendorMasterList = Loadable(lazy(() => import('../components/VendorMasterList')));
const VendorMasterData = Loadable(lazy(() => import('../components/VendorMasterData')));
const VendorMasterView = Loadable(lazy(() => import('../components/VendorMasterView')));
const RoomData = Loadable(lazy(() => import('../components/RoomData')));
const BalanceSheet = Loadable(lazy(() => import('../components/BalanceSheet')));
const PgFormOtpData = Loadable(lazy(() => import('../components/PgFormOtpData')));
const ThanksGuest = Loadable(lazy(() => import('../components/ThanksGuest')));

const MainRoutes = () => {
    console.log("main route");
    const location = useLocation();
    const validPaths = [
        '/pg_form_list', 
        '/pg_form_data', 
        '/pg_form_view',
        '/logout',
        '/building_list',
        '/building_data',
        '/building_view',
        '/user_management_list',
        '/user_management_data',
        '/user_management_view',
        '/user_reset_password',
        '/credit_data',
        '/credit_list',
        '/credit_view',
        '/debit_list',
        '/debit_data',
        '/debit_view',
        '/generate_room',
        '/room_list',
        '/dashboard',
        '/vendor_list',
        '/vendor_data',
        '/vendor_view',
        '/rental_list',
        '/rental_data',
        '/rental_view',
        '/vendor_master_list',
        '/vendor_master_data',
        '/vendor_master_view',
        '/room_data',
        '/balance_sheet',
        '/pg_form_otp_data',
        '/thanks_guest'

    ];
    const isValidPath = validPaths.includes(location.pathname);

    if (!isValidPath) {
        return null;
    }

    return (
        <MainLayout>
            <AuthGuard>
                <Routes location={location} key={location.pathname}>
                    <Route path="/dashboard" element={<LandingPage />} />
                    <Route path="/pg_form_list" element={<PgFormList />} />
                    <Route path="/pg_form_data" element={<PgFormData />} />
                    <Route path="/pg_form_view" element={<PgFormView />} />
                    <Route path="/building_list" element={<BuildingList />} />
                    <Route path="/building_data" element={<BuildingData />} />
                    <Route path="/building_view" element={<BuildingView />} />
                    <Route path="/user_management_list" element={<UserManagementList />} />
                    <Route path="/user_management_data" element={<UserManagementData />} />
                    <Route path="/user_management_view" element={<UserManagementView />} />
                    <Route path="/logout" element={<Logout />} />
                    <Route path="/user_reset_password" element={<UserResetPassword />} />
                    <Route path="/credit_data" element={<CreditData />} />
                    <Route path="/credit_list" element={<CreditList />} />
                    <Route path="/credit_view" element={<CreditView />} />
                    <Route path="/debit_list" element={<DebitList />} />
                    <Route path="/debit_data" element={<DebitData />} />
                    <Route path="/debit_view" element={<DebitView />} />
                    <Route path="/generate_room" element={<GenerateRoom />} />
                    <Route path="/room_list" element={<RoomList />} />
                    <Route path="/vendor_list" element={<VendorList />} />
                    <Route path="/vendor_data" element={<VendorData />} />
                    <Route path="/vendor_view" element={<VendorView />} />
                    <Route path="/rental_list" element={<RentalList />} />
                    <Route path="/rental_data" element={<RentalData />} />
                    <Route path="/rental_view" element={<RentalView />} />
                    <Route path="/vendor_master_list" element={<VendorMasterList />} />
                    <Route path="/vendor_master_data" element={<VendorMasterData />} />
                    <Route path="/vendor_master_view" element={<VendorMasterView />} />
                    <Route path="/room_data" element={<RoomData />} />
                    <Route path="/balance_sheet" element={<BalanceSheet />} />
                    <Route path="/pg_form_otp_data" element={<PgFormOtpData />} />
                    <Route path="/thanks_guest" element={<ThanksGuest />} />
                </Routes>
            </AuthGuard>
        </MainLayout>
    );
};

export default MainRoutes;

let BACKEND_SERVER = null;
let BACKEND_WS_SERVER = null;
if (process.env.REACT_APP_BACKEND_SERVER) {
  BACKEND_SERVER = process.env.REACT_APP_BACKEND_SERVER;
} else {
   BACKEND_SERVER = "https://pgservices.travelsmaster.in/"; 
  //BACKEND_SERVER = " http://127.0.0.1:8000/"; 
  
  // BACKEND_WS_SERVER = "wss://192.168.0.103:8000/"; 
}

const config = {
    basename: '',
    defaultPath: '/dashboard',
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 3,
    API_SERVER: BACKEND_SERVER,
    // WS_SERVER: BACKEND_WS_SERVER,
    TITLE_TXT: 'Nest In',
};

export default config;

import React, {useState} from 'react';
import '../../assets/scss/style.css';
import { Link } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import { ButtonBase, IconButton } from '@mui/material';
import { useSelector } from 'react-redux';
import config from '../../config';
import { makeStyles } from '@mui/styles';
import ApartmentIcon from '@mui/icons-material/Apartment';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import CollectionsIcon from '@mui/icons-material/Collections';
import PaymentIcon from '@mui/icons-material/Payment';

const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    }
}))
const Footer = () => {
    const account = useSelector((state) => state.account);
    const { role } = account;
    const classes = useStyles();

    return (
        <footer className={`${classes.root} no-print`}>
            <div className='footer' style={{marginTop: '100%'}}>
                <div className="row">
                    <div className='footer-icon'>
                        <RouterLink to={'/building_list'} style={{ color: 'inherit', textDecoration: 'none' }}>
                            <ApartmentIcon />
                        </RouterLink>
                        <h4 className="footer-text">Property</h4>
                    </div>
                </div>
                <div className="row">
                    <div className='footer-icon'>
                        <RouterLink to={'/vendor_list'} style={{ color: 'inherit', textDecoration: 'none' }}>
                            <PaymentIcon />
                        </RouterLink>
                        <h4 className="footer-text">Expensive</h4>
                    </div>
                </div>
                <div className="row">
                    <div className='footer-icon'>
                        <RouterLink to={'/dashboard'} style={{ color: 'inherit', textDecoration: 'none' }}>
                            <HomeIcon />
                        </RouterLink>
                        <h4 className="footer-text">Home</h4>
                    </div>
                </div>
                <div className="row">
                    <div className='footer-icon'>
                        <RouterLink to={'/rental_list'} style={{ color: 'inherit', textDecoration: 'none' }}>
                            <CollectionsIcon />
                        </RouterLink>
                        <h4 className="footer-text">Collection</h4>
                    </div>
                </div>
                <div className="row">
                    <div className='footer-icon'>
                        <RouterLink to={'/balance_sheet'} style={{ color: 'inherit', textDecoration: 'none' }}>
                            <AccountBalanceWalletIcon />
                        </RouterLink>
                        <h4 className="footer-text">Balance Report</h4>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
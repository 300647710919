export const dashboard = {
    id: 'dashboard',
    title: 'DashBoard',
    type: 'group',
    children: [
        {
            id: 'admins.view_tbllocation',
            title: 'DashBoard',
            type: 'item',
            url: '/dashboard',
            icon: 'IconDashboard',
            breadcrumbs: false
        },
    ]
};
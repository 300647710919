import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

// material-ui
import { makeStyles } from '@mui/styles';
import { Avatar, Box, ButtonBase, Button, Typography, IconButton } from '@mui/material';

// project imports
import LogoSection from '../LogoSection';
import { Link as RouterLink } from 'react-router-dom';
import configData from '../../config';
import '../../assets/scss/style.css';
import Logo from '../../ui-component/Logo';

// assets
// import { IconMenu2 } from '@tabler/icons-react';
import { useSelector } from 'react-redux';
// style constant
const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1
    },
    headerAvatar: {
        ...theme.typography.commonAvatar,
        ...theme.typography.mediumAvatar,
        transition: 'all .2s ease-in-out',
    },
    iconButton: {
        backgroundColor: '#216bfd',
        color: 'white',
        '&:hover': {
            background: theme.palette.secondary.dark,
        },
      },
    // boxContainer: {
    //     width: '200px',
    //     display: 'flex',
    //     [theme.breakpoints.down('md')]: {
    //         width: 'auto'
    //     }
    // }, 
    disp : {
        bgcolor:'primary.light'
    },
    root: {
        [theme.breakpoints.down('md')]: {
            marginRight: '10px'
        }
    }
}));

//-----------------------|| MAIN NAVBAR / HEADER ||-----------------------//

const Header = ({ handleLeftDrawerToggle }) => {
    const classes = useStyles();
    const account = useSelector((state) => state.account);
    const { user } = account;

    return (
        <React.Fragment>
            {/* logo & toggler button */}
            {/* <div className={classes.boxContainer}> */}
                {/* <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
                    <LogoSection />
                </Box> */}
                {/* <ButtonBase sx={{ borderRadius: '1px',  overflow: 'hidden' }}>
                    <Avatar variant="rounded" className={classes.headerAvatar} onClick={handleLeftDrawerToggle} color="inherit">
                    <IconButton className={classes.iconButton} >
                    <IconMenu2 />
                </IconButton>
                        
                    </Avatar>
                </ButtonBase> */}
            {/* </div> */}
            <Typography variant="h1" component="div" sx={{ fontSize: '24px',flexGrow: 1, textAlign: 'left', p:2, minWidth: 50, fontFamily: 'cursive', color:'#1a8cff' }}>
                    {/* Nest In */}
                <Logo />
            </Typography>
            {user != 'guest_user' && 
                <div className={classes.root}>
                    <RouterLink to={'/logout'}>
                        <Button variant="outlined" size='small' >Logout</Button>
                    </RouterLink>
                </div>
            }
        </React.Fragment>
    );
};

Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func
};

export default Header;

import React, { lazy } from 'react';
import { Route, Routes, useLocation, Navigate } from 'react-router-dom';
import GuestGuard from '../utils/route-guard/GuestGuard';
import MinimalLayout from '../layout/MinimalLayout';
import NavMotion from '../layout/NavMotion';
import Loadable from '../ui-component/Loadable';

const LoginHome = Loadable(lazy(() => import('../views/pages/authentication/Home')));
const AuthLogin = Loadable(lazy(() => import('../views/pages/authentication/login')));
const ForgotPassword = Loadable(lazy(() => import('../views/pages/authentication/ForgotPassword')));

//-----------------------|| ROUTING ||-----------------------//

const LoginRoutes = () => {
    const location = useLocation();
    console.log("login route");

    const validPaths = ['/login', '/authlogin', '/forgot_password'];
    const isValidPath = validPaths.includes(location.pathname);

    if (!isValidPath) {
        return null;
    }

    return (
        <MinimalLayout>
            <NavMotion>
                <GuestGuard>
                    <Routes location={location} key={location.pathname}>
                        <Route path="/login" element={<LoginHome />} />
                        <Route path="/authlogin" element={<AuthLogin />} />
                        <Route path="/forgot_password" element={<ForgotPassword />} />
                    </Routes>
                </GuestGuard>
            </NavMotion>
        </MinimalLayout>
    );
};

export default LoginRoutes;

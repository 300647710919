
//-----------------------|| DASHBOARD MENU ITEMS ||-----------------------//

export const admin = {
    id: 'admin',
    title: 'Master',
    type: 'group',
    children: [
        {
            id: 'admins.view_tblbuilding',
            title: 'Building',
            type: 'item',
            url: '/building_list',
            icon: 'IconHome2',
            breadcrumbs: false
        },
        {
            id: 'admins.view_tblroom',
            title: 'Rooms',
            type: 'item',
            url: '/room_list',
            icon: 'IconDoor',
            breadcrumbs: false
        },
        {
            id: 'admins.view_tblguest',
            title: 'Guest',
            type: 'item',
            url: '/pg_form_list',
            icon: 'IconFileTypography',
            breadcrumbs: false
        },
        {
            id: 'admins.view_tblvendor',
            title: 'Vendors',
            type: 'item',
            url: '/vendor_master_list',
            icon: 'IconCalendarEvent',
            breadcrumbs: false
        },
    ]
};

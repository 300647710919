
//-----------------------|| securities MENU ITEMS ||-----------------------//
export const accounts = {
    id: 'accounts',
    title: 'Accounts',
    type: 'group',
    children: [
        {
            id: 'account.view_tblcreditamount',
            title: 'Credit',
            type: 'item',
            url: '/credit_list',
            icon: 'IconCreditCardRefund',
            breadcrumbs: false
        },
        {
            id: 'account.view_tbldebitamount',
            title: 'Debit',
            type: 'item',
            url: '/debit_list',
            icon: 'IconCreditCardPay',
            breadcrumbs: false
        },
        {
            id: 'account.view_tblvendorbilling',
            title: 'Vendor Bill',
            type: 'item',
            url: '/vendor_list',
            icon: 'IconReceipt',
            breadcrumbs: false
        },
        {
            id: 'account.view_tblinvoice',
            title: 'Rent Receipt',
            type: 'item',
            url: '/rental_list',
            icon: 'IconFileDollar',
            breadcrumbs: false
        },
        {
            id: 'account.view_tblpaymentcategory',
            title: 'Balance Report',
            type: 'item',
            url: '/balance_sheet',
            icon: 'IconClipboardList',
            breadcrumbs: false
        },
        {
            id: 'auth.view_user',
            title: 'User Management',
            type: 'item',
            url: '/user_management_list',
            icon: 'IconUsers',
            breadcrumbs: false
        },
    ]
};

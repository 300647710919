import React from 'react';
import { useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

import AppRoutes from './routes/AppRoutes';
import NavigationScroll from './layout/NavigationScroll';
import Theme from './themes';
import Footer from './layout/Footer';

//-----------------------|| APP ||-----------------------//

const App = () => {
    const Customization = useSelector((state) => state.customization);
    const account = useSelector((state) => state.account);
    const LoggedIn = account.isLoggedIn;
    const user = account.user;

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={Theme(Customization)}>
                <CssBaseline />
                <NavigationScroll>
                    <AppRoutes />
                </NavigationScroll>
                {LoggedIn && user != 'guest_user' ? <Footer /> : null}
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;

import React, { useEffect, useState } from 'react';
import { Navigate, Routes, Route, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import configData from '../config';
import { ACCOUNT_INITIALIZE, LOGOUT } from '../store/actions';

// routes
import MainRoutes from './MainRoutes';
import LoginRoutes from './LoginRoutes';

//-----------------------|| ROUTING RENDER ||-----------------------//

const AppRoutes = () => {
    const dispatcher = useDispatch();
    const location = useLocation();
    const [redirectUrl, setRedirectUrl] = useState(null);
    const [loading, setLoading] = useState(true);
    const TOKEN_EXPIRATION_DURATION = 30 * 60 * 1000;
    const account = useSelector((state) => state.account);
    const user = account.user;
    function useQuery() {
        return new URLSearchParams(location.search);
    }

    const query = useQuery();
    const pathname = location.pathname;

    const checkTokenExpiration = () => {
        const expirationTime = localStorage.getItem('tokenExpiration');
        if (expirationTime && Date.now() > expirationTime) {
            dispatcher({ type: LOGOUT });
            setRedirectUrl('/login');
        }
    };

    useEffect(() => {
        if(user == 'guest_user'){
            checkTokenExpiration();
        }
    }, []);

    useEffect(() => {
        const autoLogin = async () => {
            try {
                const response = await axios.post(`${configData.API_SERVER}api/token/`, {
                    password: 'Itcus@123',
                    username: 'guest_user'
                });

                if (response.data.access) {
                    const userToken = response.data.access;
                    const refreshToken = response.data.refresh;

                    axios.defaults.headers.common['Authorization'] = `Bearer ${userToken}`;

                    // Store the expiration time
                    const expirationTime = Date.now() + TOKEN_EXPIRATION_DURATION;
                    localStorage.setItem('tokenExpiration', expirationTime);

                    // Set a timer to log the user out after expiration
                    setTimeout(() => {
                        dispatcher({ type: LOGOUT });
                        setRedirectUrl('/login'); // Optionally redirect
                    }, TOKEN_EXPIRATION_DURATION);

                    // Proceed with additional API calls
                    const homeResponse = await axios.get(`${configData.API_SERVER}api/home/`);
                    dispatcher({
                        type: ACCOUNT_INITIALIZE,
                        payload: { isLoggedIn: true, user: 'guest_user', token: userToken, refresh: refreshToken }
                    });

                    // Set redirect URL after successful login
                    setRedirectUrl(`/pg_form_data?id=${query.get('guest')}&from_message=${true}`);
                }
            } catch (err) {
                console.error(err);
                setRedirectUrl('/login');
            } finally {
                setLoading(false); // Set loading to false when done
            }
        };

        if (pathname === '/' && query.has('guest')) {
            autoLogin();
        } else {
            setRedirectUrl('/dashboard');
            setLoading(false);
            if(user == 'guest_user'){
                setRedirectUrl('/thanks_guest');
                setLoading(false);
            } else {
                setRedirectUrl('/dashboard');
                setLoading(false);
            }
        }
    }, [pathname, query, dispatcher]); // Dependencies

    if (loading) {
        return <div>Loading...</div>; // Optional loading state
    }

    return (
        <>
            <Routes>
                <Route path="/" element={<Navigate to={redirectUrl} />} />
            </Routes>
            <LoginRoutes />
            <MainRoutes />
        </>
    );
};

export default AppRoutes;
